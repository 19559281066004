@font-face {
  font-family: 'Gobold';
  src: url('./style/font/goboldregular.otf');
}
*{
  font-family: 'Gobold' !important;
  /* font-weight: 400; */
}


